import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../../api/axiosInstance';
import Swal from 'sweetalert2'; // Import SweetAlert2
import withReactContent from 'sweetalert2-react-content'; // Import React components for SweetAlert2

import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import LoadingTable from '../../components/LoadingTable';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import Papa from 'papaparse';


const MySwal = withReactContent(Swal); // Create a SweetAlert2 instance with React components


function AllUser() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/admin/all-user?page=${currentPage}&limit=${limit}&search=${searchTerm}`);
      setData(response.data.users);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  // Function to handle delete
  const handleDelete = (categoryId) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        popup: 'custom-swal-popup', // Add a class to the entire dialog
        confirmButton: 'btn-danger', // Add a class to the confirm button
      },
    }).then((result) => {
      if (result.isConfirmed) {


        axiosInstance.delete(`/admin/delete-rating/${categoryId}`)
          .then(() => {
            // Refresh the data or update the state after successful deletion
            fetchData();
            toast.success("Deleting Rating success!");
          })
          .catch((error) => {
            console.error('Error deleting data:', error);
            toast.error("Error deleting data");
          });
      }
    });
  };


  const handleStatusChange = (StatusId) => async (event) => {
    const newStatus = event.target.value;
    setLoading(true);

    try {
      await axiosInstance.put(`/admin/update-user/${StatusId}`, { status: newStatus });
      setLoading(false);
      fetchData(currentPage);
      toast.success("Status Updated success!");
    } catch (error) {
      console.error('Error updating rating status:', error);
      setLoading(false);
    }
  };



  const inputRef = React.createRef();

  const handleSearchInputChange = (e) => {
    setCurrentPage(1);
    setTotalPages(1)
    setSearchTerm(e.target.value);


  };

  const handleSearch = () => {
    fetchData(currentPage);

  };

  const [isImport, setisImport] = useState(false);

  const [file, setFile] = useState(null);


  const ExportData = async () => {
    try {
      const response = await axiosInstance.get(`/admin/export/allusers/`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'allusers_data.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error exporting data:', error);
      toast.error('Error exporting data:', error);
    }

  };



  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


  const handleImport = async () => {
    if (!file) {
      toast.error('Please select a file to import');
      return;
    }


    const reader = new FileReader();

    // Handle errors that might occur during file reading
    reader.onerror = () => {
      toast.error('Error reading the file');
    };

    reader.onload = async () => {
      const csvData = reader.result;

      // Check if the file is empty
      if (!csvData || csvData.length === 0) {
        toast.error('The selected file is empty');
        return;
      }

      const { data } = Papa.parse(csvData, { header: true });

      try {

        setisImport(true);

        const formattedData = data.map(row => {
          // Ensure that the properties exist before accessing them
          const formattedRow = {
            u_id: row.u_id,
            username: row.username,
            email: row.email,
            phone: parseInt(row.phone),
            address: row.address,
          };

          return formattedRow;
        });

        const response = await axiosInstance.post(`/admin/import/allUser`, formattedData);

        if (response.data.success) {
          toast.success('User imported successfully!');
          console.log('Response:', response.data);
          setisImport(false);
        } else {
          toast.error('Error importing User:', response.data.message);
        }


      } catch (error) {
        console.error('Error importing data:', error);
        toast.error('Error importing User:', error.message);
      }
    };

    reader.readAsText(file);
  };



  return (
    <>
      <Sidebar />
      <main className="content">
        <Navbar />


        {isImport && (
          <>
            <div
              className=""
              style={{
                width: "100%",
                borderRadius: 5,
                margin: 0,
                height: "100%",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 99999,
                background: '#ffffff8f',
                left: 0,
                top: 0,
                color: "white",
                fontSize: "20em"
              }}
            >
              <div class="loading_icon"></div>
            </div>

          </>)}


        <div className="py-4">


          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0 d-flex">
              <h1 className="h4"> All Users</h1>


            </div>

            <div className='d-flex gap-2'>

              <button type="button" className="btn btn-sm btn-primary " data-bs-toggle="modal"
                data-bs-target="#modal-upload" >

                <svg
                  className="icon icon-xs me-2"

                  xmlns="http://www.w3.org/2000/svg"

                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                  strokeLinecap="butt"
                  strokeLinejoin="round"
                >
                  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                </svg>

                Import
              </button>{" "}

              <button
                onClick={ExportData}
                className="btn btn-sm btn-gray-800 d-inline-flex align-items-center"
              >

                <svg

                  className="icon icon-xs me-2"

                  xmlns="http://www.w3.org/2000/svg"

                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                  strokeLinecap="butt"
                  strokeLinejoin="round"
                >
                  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3" />
                </svg>


                Export Users
              </button>
            </div>

          </div>
        </div>




        <div
          className="modal fade"
          id="modal-upload"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="modal-upload"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

              <div className="modal-body">

                <div id="uploadData">
                  <div className="uploadbox">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      width={40}
                      height={40}
                      fill="black"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* SVG path content */}
                    </svg>
                    <span id="filname"> Drag And Drop Or Select CSV File </span>
                    <input
                      type="file"
                      id="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      name='file'
                    />
                  </div>


                </div>

              </div>
              <div className="modal-footer">

                <button
                  id="uploadButton"
                  className="btn btn-gray-800 mt-2 animate-up-2"
                  onClick={handleImport} type="button"
                >
                  Upload
                </button>



                <button
                  type="button"
                  className="btn btn-gray-300 ms-auto"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>




        <div className="card">
          <div className="table-responsive py-4">

            {loading ? (
              <LoadingTable />
            ) : (
              <>


                <div className="datatable-top">
                  <div className="datatable-dropdown">
                    <label>
                      <select className="datatable-selector" value={limit} onChange={(e) => {
                        setlimit(parseInt(e.target.value, 10));
                        setCurrentPage(1); // Reset current page to 1 when the limit changes
                      }} >
                        <option value={5}>5</option>
                        <option value={10} >
                          10
                        </option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                      </select>{" "}

                      entries per page
                    </label>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div className="datatable-search">
                      <input
                        className="datatable-input"
                        type="text"
                        placeholder="Search..." value={searchTerm}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                    <button className="btn btn-primary" onClick={handleSearch}> Search
                    </button>

                  </div>
                </div>




                <table className="table table-flush">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row) => (
                      <tr key={row._id}>
                        <td>{row?.u_id}</td>
                        <td>{row.username}</td>
                        <td>  {row.email} </td>
                        <td>  {row.phone} </td>

                        <td>

                          <select style={{ width: "fit-content" }} className={`form-control select ${row.status == 0 ? "text-white btn-danger" : "text-white btn-success"}`} value={row.status} name="status" onChange={handleStatusChange(row._id)}>
                            <option value="1">Active</option>
                            <option value="0">Inactive </option>
                          </select>

                          <br />

                        </td>

                        <td>
                          <Link class="btn btn-secondary" to={`/admin/user/${row._id}`}>

                            View <i class="ri-user-fill"></i></Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="datatable-bottom">
                  <div className="datatable-info">  </div>
                  <nav className="datatable-pagination">
                    <ul class="datatable-pagination-list">

                      <li className={`datatable-pagination-list-item ${currentPage === 1 ? 'datatable-hidden' : 'datatable-active'}`}>
                        <button data-page="1" class="datatable-pagination-list-item-link" onClick={handlePreviousPage} disabled={loading || currentPage === 1}
                          aria-label="Page 1">‹</button>
                      </li>
                      <li>
                        <div className="datatable-info py-1 px-3">Showing  Page{' '}
                          <strong>
                            Showing Page  {currentPage} of {totalPages}

                          </strong>{' '} </div>
                      </li>
                      <li className={`datatable-pagination-list-item ${currentPage === totalPages ? 'datatable-hidden' : 'datatable-active'}`}>
                        <button data-page="2" class="datatable-pagination-list-item-link" aria-label="Page 2" onClick={handleNextPage} disabled={loading || currentPage === totalPages}
                        >  {loading ? 'Loading...' : '›'} </button>
                      </li>
                    </ul></nav>
                </div>



              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}

export default AllUser;
