import React, { useState, useEffect } from "react";
import axios from 'axios';
import axiosInstance from "../../../api/axiosInstance";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import toast from "react-hot-toast";
import Nestable from "react-nestable";
import Gallery from "../../components/Gallery";

const ThemeSetting = () => {

  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state

  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    meta_title: "",
    meta_description: "",
    meta_head: "",
    footer_credit: "",
  });



  const [PrintFormData, setPrintFormData] = useState({
    FrontLeftChest: "0",
    FrontRightChest: "0",
    FrontCenterChest: "0",
    BackTopBack: "0",
    BackNeckOfNeck: "0",
    BackBottomBack: "0",
    FrontLeftSleeve: "0",
    FrontRightSleeve: "0",
    FrontInsideNeck: "0",
  }
  );

  const [EmbroideryFormData, setEmbroideryFormData] = useState({
    FrontLeftChest: "0",
    FrontRightChest: "0",
    FrontCenterChest: "0",
    BackTopBack: "0",
    BackNeckOfNeck: "0",
    BackBottomBack: "0",
    FrontLeftSleeve: "0",
    FrontRightSleeve: "0",
    FrontInsideNeck: "0",
  }
  );


  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };


  const handlePrintChange = (e) => {
    const { name, value } = e.target;
    setPrintFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEmbroideryChange = (e) => {
    const { name, value } = e.target;
    setEmbroideryFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }


  const getData = async () => {
    try {
      const { data } = await axiosInstance.get(`/home-data`);
      setItems(data.homeData);
      setFormData(data.homeData);
      setEmbroideryFormData(data.homeData.embroidery);
      setPrintFormData(data.homeData.print);
      setIsLoading(false); // Set loading state to false in case of an error
      console.log('data', data.homeData)
    }
    catch (error) {
      console.log(error);
      toast.error("Error fetching Single Blog!");
      setIsLoading(false); // Set loading state to false in case of an error
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [collapseAll, setCollapseAll] = useState(false);
  const [editMode, setEditMode] = useState(null); // Track the edited item ID

  const [newMenuItem, setNewMenuItem] = useState({
    id: Date.now(),
    text: "",
    link: "",
    target: "_self",
    children: []
  });




  const handleEditMenu = (items, itemId, newText, newLink, newTarget) => {

    return items.map(item => {
      if (item.id === itemId) {
        return { ...item, text: newText, link: newLink, target: newTarget };
      } else if (item.children) {
        return { ...item, children: handleEditMenu(item.children, itemId, newText, newLink, newTarget) };
      }
      return item;
    });
  };

  const handleDeleteMenu = (items, itemId) => {
    return items.filter(item => {
      if (item.id === itemId) {
        return false; // Exclude the item with the specified itemId
      } else if (item.children) {
        // Recursively filter children if they exist
        item.children = handleDeleteMenu(item.children, itemId);
        return true;
      }
      return true;
    });
  };



  const submitData = async () => {
    setSubmitLoading(false);
    const meta_favicon = document.getElementById("meta_favicon");
    const meta_logo = document.getElementById("meta_logo");

    const updatedFormData = {
      ...formData,
      // meta_favicon: meta_favicon.src,
      // meta_logo: meta_logo.src,
      print: PrintFormData,
      embroidery: EmbroideryFormData,
    };

    console.log('formData', PrintFormData)
    try {
      const admintoken = localStorage.getItem("adminToken");
      if (admintoken) {
        await axiosInstance.put(`/admin/edit-home`, updatedFormData);
        toast.success("Header Updated successfully!");

      }
    } catch (error) {
      console.error("Error On Menu:", error);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }
  };

  const saveMenu = () => {
    // Here you can save the menu items using axios or any other method
    // Example:

    console.log(items)
    axiosInstance.post('/api/menu', { items })
      .then(response => {
        toast.success("Menu saved successfully!");
      })
      .catch(error => {
        toast.error("Failed to save menu.");
      });
  };



  return (
    <>
      <Sidebar />
      <main className="content">
        <Navbar />

        <nav
          aria-label="breadcrumb"
          className="d-none d-md-inline-block"
        >
          <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
            <li className="breadcrumb-item">
              <a href="#">
                <svg
                  className="icon icon-xxs"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
              </a>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dashboard">Admin</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Theme Setting
            </li>
          </ol>
        </nav>




        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="card border-0 shadow components-section">
              <div className="card-body" >


                <div className="col-sm-12 mb-3">
                  <label htmlFor="meta_title">Meta Title</label>{" "}
                  <input
                    className="form-control"
                    id="meta_title"
                    type="text"
                    name="meta_title"
                    value={formData.meta_title}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-sm-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="address">Meta Description</label>{" "}
                    <textarea
                      className="form-control"
                      id="meta_description"
                      type="text"
                      name="meta_description"
                      value={formData.meta_description}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="address">Head Code</label>
                    <textarea
                      className="form-control"
                      id="meta_head"
                      type="text"
                      name="meta_head"
                      style={{ background: "#1f2937", color: "#f8bd7a " }}
                      value={formData.meta_head}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">



                  <div className="col-md-6 mb-3">
                    <label htmlFor="meta_title">Meta Logo</label>{" "}
                    <input
                      className="form-control"
                      id="meta_logo"
                      type="text"
                      name="meta_logo"
                      value={formData.meta_logo}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="meta_title">Meta Favicon</label>{" "}
                    <input
                      className="form-control"
                      id="meta_favicon"
                      type="text"
                      name="meta_favicon"
                      value={formData.meta_favicon}
                      onChange={handleChange}
                    />
                  </div>





                  <div className="col-sm-12 mb-3">
                    <label htmlFor="footer_credit">Footer Credit</label>{" "}
                    <input
                      className="form-control"
                      id="footer_credit"
                      type="text"
                      name="footer_credit"
                      value={formData.footer_credit}
                      onChange={handleChange}
                    />
                  </div>



                  <div className="col-sm-6 mb-3">
                    <label htmlFor="footer_credit">Phone Number</label>{" "}
                    <input
                      className="form-control"
                      id="phone"
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>


                  <div className="col-sm-6 mb-3">
                    <label htmlFor="footer_credit">Email ID</label>{" "}
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>


                  <div className="col-sm-12 mb-3">
                    <div className="form-group">
                      <label htmlFor="address">Address</label>{" "}
                      <textarea
                        className="form-control"
                        id="address"
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 bg-primary-light border rounded p-3">
                      <h5 className="fw-bold">Print Pricing</h5>
                      <hr />


                      <div className="form-group  mb-2">
                        <label htmlFor="Qty13"> Qty
                          (1-3) </label>
                        <input
                          className="form-control"
                          id="Qty13"
                          type="number"
                          name="Qty13"
                          value={PrintFormData.Qty13}
                          onChange={handlePrintChange}
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label htmlFor="Qty46"> Qty
                          (4-6) </label>
                        <input
                          className="form-control"
                          id="Qty46"
                          type="number"
                          name="Qty46"
                          value={PrintFormData.Qty46}
                          onChange={handlePrintChange}
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label htmlFor="Qty712"> Qty
                          (7-12) </label>
                        <input
                          className="form-control"
                          id="Qty712"
                          type="number"
                          name="Qty712"
                          value={PrintFormData.Qty712}
                          onChange={handlePrintChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty1324">Qty (13-24)</label>{" "}
                        <input
                          className="form-control"
                          id="Qty1324"
                          type="number"
                          name="Qty1324"
                          value={PrintFormData.Qty1324}
                          onChange={handlePrintChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty2549"> Qty
                          (25-49) </label>
                        <input
                          className="form-control"
                          id="Qty2549"
                          type="number"
                          name="Qty2549"
                          value={PrintFormData.Qty2549}
                          onChange={handlePrintChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty5099"> Qty
                          (50-99) </label>
                        <input
                          className="form-control"
                          id="Qty5099"
                          type="number"
                          name="Qty5099"
                          value={PrintFormData.Qty5099}
                          onChange={handlePrintChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty100249"> Qty
                          (100-249) </label>
                        <input
                          className="form-control"
                          id="Qty100249"
                          type="number"
                          name="Qty100249"
                          value={PrintFormData.Qty100249}
                          onChange={handlePrintChange}
                        />
                      </div>






                    </div>

                    <div className="col-md-6 bg-primary-light border rounded p-3">
                      <h5 className="fw-bold">Embroidery Pricing</h5>
                      <hr />



                      <div className="form-group  mb-2">
                        <label htmlFor="Qty13"> Qty
                          (1-3) </label>
                        <input
                          className="form-control"
                          id="Qty13"
                          type="number"
                          name="Qty13"

                          value={EmbroideryFormData.Qty13}
                          onChange={handleEmbroideryChange}
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label htmlFor="Qty46"> Qty
                          (4-6) </label>
                        <input
                          className="form-control"
                          id="Qty46"
                          type="number"
                          name="Qty46"

                          value={EmbroideryFormData.Qty46}
                          onChange={handleEmbroideryChange}
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label htmlFor="Qty712"> Qty
                          (7-12) </label>
                        <input
                          className="form-control"
                          id="Qty712"
                          type="number"
                          name="Qty712"

                          value={EmbroideryFormData.Qty712}
                          onChange={handleEmbroideryChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty1324">Qty (13-24)</label>{" "}
                        <input
                          className="form-control"
                          id="Qty1324"
                          type="number"
                          name="Qty1324"

                          value={EmbroideryFormData.Qty1324}
                          onChange={handleEmbroideryChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty2549"> Qty
                          (25-49) </label>
                        <input
                          className="form-control"
                          id="Qty2549"
                          type="number"
                          name="Qty2549"

                          value={EmbroideryFormData.Qty2549}
                          onChange={handleEmbroideryChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty5099"> Qty
                          (50-99) </label>
                        <input
                          className="form-control"
                          id="Qty5099"
                          type="number"
                          name="Qty5099"

                          value={EmbroideryFormData.Qty5099}
                          onChange={handleEmbroideryChange}
                        />
                      </div>


                      <div className="form-group mb-2">
                        <label htmlFor="Qty100249"> Qty
                          (100-249) </label>
                        <input
                          className="form-control"
                          id="Qty100249"
                          type="number"
                          name="Qty100249"

                          value={EmbroideryFormData.Qty100249}
                          onChange={handleEmbroideryChange}

                        />
                      </div>







                    </div>

                  </div>
                  <hr className="mt-3" />

                  <div className="card card-body border-0 shadow mb-4 mb-xl-0 d-none">
                    <h2 className="h5 mb-4">Payment Methods</h2>

                    <div className="justify-content-between row px-2">
                      <div className="col-md-5 d-flex align-items-center justify-content-between px-0 border-bottom">
                        <div>
                          <h3 className="h6 mb-1">COD</h3>
                          <p className="small pe-4">

                            COD stands for "Cash on Delivery."
                          </p>
                        </div>
                        <div>
                          <div className="form-check form-switch">
                            {formData.cash !== undefined && (<input
                              className="form-check-input"
                              type="checkbox"
                              name="cash"
                              id="cash"
                              defaultChecked={formData.cash === 'true' && "checked"}
                              defaultValue={1} onChange={handleChange}
                            />)}
                            <label className="form-check-label" htmlFor="cash" />
                          </div>
                        </div>
                      </div>

                      <div className=" col-md-5 d-flex align-items-center justify-content-between px-0 border-bottom">
                        <div>
                          <h3 className="h6 mb-1">Razorpay</h3>
                          <p className="small pe-4">

                            Razorpay Payment Gateway
                          </p>
                        </div>
                        <div>
                          <div className="form-check form-switch">
                            {formData.razorpay !== undefined && (<input
                              className="form-check-input"
                              type="checkbox"
                              name="razorpay"
                              id="razorpay"
                              defaultChecked={formData.razorpay === 'true' && "checked"}
                              defaultValue={1} onChange={handleChange}
                            />)}
                            <label className="form-check-label" htmlFor="razorpay" />
                          </div>
                        </div>
                      </div>

                    </div>



                  </div>




                </div>

                <div className="input-group mt-4 mb-2">

                  {SubmitLoading ? (
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={submitData}
                    >
                      Update Theme
                    </button>
                  ) : (
                    <button
                      class="btn btn-secondary btn-sm"
                      type="button"
                      disabled
                    >
                      <span class="ms-1">Loading...</span>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}



                </div>

              </div>

            </div>
          </div>
        </div>

        <Gallery />

      </main >
    </>
  );
};

export default ThemeSetting;
