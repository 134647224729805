import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Login from "./Admin/pages/Login";
import Dashboard from './Admin/pages/Dashboard';
import Addblog from './Admin/pages/Blogs/Addblog';
import AddGallery from './Admin/pages/Gallery/addGallery';
import BuildBlogs from './Admin/pages/builder/buildBlogs';
import AdminAllBlogs from './Admin/pages/Blogs/AllBlogs';
import EditAdminBlogs from './Admin/pages/Blogs/EditAdminBlogs';
import AllCategory from './Admin/pages/category/AllCategory';
import EditCategory from './Admin/pages/category/EditCategory';
import AddCategory from './Admin/pages/category/AddCategory';
import AllProduct from './Admin/pages/product/AllProduct';
import EditProduct from './Admin/pages/product/EditProduct';
import AddProduct from './Admin/pages/product/AddProduct';

import AddAttribute from './Admin/pages/attribute/AddAttribute';
import EditAttribute from './Admin/pages/attribute/EditAttribute';
import AllAttribute from './Admin/pages/attribute/AllAttribute';

import AddTag from './Admin/pages/tag/AddTag';
import EditTag from './Admin/pages/tag/EditTag';
import AllTag from './Admin/pages/tag/AllTag';

import AddPrivatestore from './Admin/pages/PrivateStore/AddPrivatestore';
import EditPrivatestore from './Admin/pages/PrivateStore/EditPrivatestore';
import AllPrivatestore from './Admin/pages/PrivateStore/AllPrivatestore';

import MenuBuilder from './Admin/pages/builder/MenuBuilder';

import AddPrivateProduct from './Admin/pages/PrivateProduct/AddPrivateProduct';
import AllPrivateProduct from './Admin/pages/PrivateProduct/AllPrivateProduct';
import EditPrivateProduct from './Admin/pages/PrivateProduct/EditPrivateProduct';

import AllOrder from './Admin/pages/order/AllOrder';
import OrderView from './Admin/pages/order/OrderView';
import AllUser from './Admin/pages/user/AllUser';
import UserView from './Admin/pages/user/UserView';
import HomeLayout from './Admin/pages/home/HomeLayout';
import FooterMenuBuilder from './Admin/pages/builder/FooterMenuBuilder';
import ThemeSetting from './Admin/pages/home/ThemeSetting';

import AddPage from './Admin/pages/pages/AddPage';
import AllPage from './Admin/pages/pages/AllPage';
import EditPage from './Admin/pages/pages/EditPage';


import { Toaster } from "react-hot-toast";
import './Admin/assets/css/volt.css';


const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};


function App() {

  useEffect(() => {
    // Dynamically create a <link> element to load the CSS file
    const linkElement = document.createElement("link");
    linkElement.rel = "stylesheet";
    linkElement.href = "../assets/css/volt.css"; // Adjust the path as needed
    document.head.appendChild(linkElement);
  }, []);

  return (

    <>

      <Toaster />

      <Routes>
        <Route path="/" element={<Login />} />


        <Route path="/admin/" element={<Login />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/addblog" element={<Addblog />} />
        <Route path="/admin/add-gallery" element={<AddGallery />} />
        <Route path="/admin/buildBlogs/:slug" element={<BuildBlogs />} />
        <Route path="/admin/allblogs" element={<AdminAllBlogs />} />
        <Route path="/admin/edit-blog/:slug" element={<EditAdminBlogs />} />

        <Route path="/admin/add-category/" element={<AddCategory />} />
        <Route path="/admin/all-category/" element={<AllCategory />} />
        <Route path="/admin/edit-category/:slug" element={<EditCategory />} />


        <Route path="/admin/add-product/" element={< AddProduct />} />
        <Route path="/admin/all-product/" element={< AllProduct />} />
        <Route path="/admin/edit-product/:slug" element={<EditProduct />} />

        <Route path="/admin/add-attribute/" element={< AddAttribute />} />
        <Route path="/admin/all-attribute/" element={< AllAttribute />} />
        <Route path="/admin/edit-attribute/:slug" element={<EditAttribute />} />

        <Route path="/admin/add-tag/" element={< AddTag />} />
        <Route path="/admin/all-tag/" element={< AllTag />} />
        <Route path="/admin/edit-tag/:slug" element={<EditTag />} />

        <Route path="/admin/add-private-store/" element={< AddPrivatestore />} />
        <Route path="/admin/all-private-store/" element={< AllPrivatestore />} />
        <Route path="/admin/edit-private-store/:slug" element={<EditPrivatestore />} />

        <Route path="/admin/add-menu" element={<MenuBuilder />} />

        <Route path="/admin/home-layout" element={<HomeLayout />} />


        <Route path="/admin/add-private-product/" element={< AddPrivateProduct />} />
        <Route path="/admin/all-private-product/" element={< AllPrivateProduct />} />
        <Route path="/admin/edit-private-product/:slug" element={<EditPrivateProduct />} />
        <Route path="/admin/all-user" element={<AllUser />} />

        <Route path="/admin/user/:slug" element={<UserView />} />

        <Route path="/admin/all-order" element={<AllOrder />} />
        <Route path="/admin/order/:userId/:orderId" element={<OrderView />} />
        <Route path="/admin/add-footer-menu" element={<FooterMenuBuilder />} />
        <Route path="/admin/theme-setting" element={<ThemeSetting />} />

        <Route path="/add-page/" element={< AddPage />} />
        <Route path="/all-page/" element={< AllPage />} />
        <Route path="/edit-page/:slug" element={<EditPage />} />


      </Routes>
      <ScrollToTop />

    </>
  );
}

export default App;