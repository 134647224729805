import React, { useState, useEffect, useContext, Component } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import toast from "react-hot-toast";
import axios from 'axios';
import axiosInstance from '../../../api/axiosInstance';

function generateSlug(text) {
    // Convert to lowercase and remove disallowed characters
    var slug = text.toLowerCase().replace(/[^\w\s-]/g, '');

    // Replace spaces with hyphens
    slug = slug.replace(/\s+/g, '-');

    return slug;
}
function generateInput(text) {
    // Convert to lowercase and remove disallowed characters
    var slug = text;
    return slug;
}



function chooseimg() {
    // Get all elements with the class 'image-preview'
    const imagePreviewElements = document.querySelectorAll('.getimg');

    // Attach a click event listener to each element with the class 'image-preview'
    imagePreviewElements.forEach((imageElement) => {
        imageElement.addEventListener('click', () => {
            // Call the displayImageInfo function with the clicked image element
            displayImageInfo(imageElement);
        });
    });

}

function displayImageInfo(imageElement) {

    const previewImg = document.getElementById('image-preview');

    const inputElement = document.getElementById('image');
    // Get the src attribute of the clicked image
    var src = imageElement.getAttribute("src");

    // Set the value of the input to the src attribute
    inputElement.value = src;

    previewImg.src = inputElement.value;

    console.log(src)
}

const EditAdminBlogs = () => {



    const navigate = useNavigate();

    const [gallery, setGallery] = useState([]);
    const [isLoading, setIsLoading] = useState(true);



    const [blog, setBlog] = useState({});

    const { slug } = useParams();

    const getBlog = async () => {
        try {
            const { data } = await axiosInstance.get(`/get-blog/${slug}`);
            setBlog(data.blog);

        }
        catch (error) {
            console.log(error);
            toast.error("Error fetching Single Blog!");
        }
    };


    const getGallery = async () => {
        try {
            const { data } = await axiosInstance.get('/admin/allgallery');
            if (data?.success) {
                setGallery(data.gallery);
            }
            setIsLoading(false); // Set loading state to false after fetching data
            chooseimg();

        } catch (error) {
            console.log(error);
            toast.error("Error fetching Gallery!");
            setIsLoading(false); // Set loading state to false in case of an error
        }
    };



    const submitBlog = async () => {

        const titlee = document.getElementById('title');
        const slugg = document.getElementById('slug');
        const metaTitle = document.getElementById('metaTitle');
        const metaDes = document.getElementById('metaDes');
        const metaKeywords = document.getElementById('metaKeywords');
        const Blogimg = document.getElementById('image');

        let formData = {}; // Initialize an empty object

        formData = {
            title: titlee.value,
            image: Blogimg.value,
            slug: slugg.value,
            metaTitle: metaTitle.value,
            metaDescription: metaDes.value,
            metaKeywords: metaKeywords.value
        };

        try {
            const admintoken = localStorage.getItem('adminToken');
            if (admintoken) {
                // Send a POST request to your backend API endpoint

                await axiosInstance.put(`/update-blog/${slug}`, formData);

                // Handle success, e.g., show a success message to the user
                console.log('Blog Edit successfully!');
                toast.success("Blog Edit successfully!");
                navigate('/admin/allblogs');
            }

        } catch (error) {
            // Handle errors, e.g., show an error message to the user
            console.error('Error On Blog:', error);
            console.log(formData)
            toast.error(error.response.data.message);

        }
    };


    useEffect(() => {
        getBlog();

        getGallery();
        const checkUserToken = async () => {
            console.log('Effect is running');
            // Check if this is printed multiple times
            const usertoken = localStorage.getItem('adminToken');
            if (!usertoken) {
                console.log('Token !found in local storage');

                navigate('/admin');

                toast.success("Please login first");
            }
        }
        checkUserToken();
    }, [navigate]);

    useEffect(() => {


        const goBackButton = document.getElementById('goBackButton');
        const titleInput = document.getElementById('title');
        const metaTitleInput = document.getElementById('metaTitle');
        const metaDesTextarea = document.getElementById('metaDes');
        const metaKeywords = document.getElementById('metaKeywords');
        const slugInput = document.getElementById('slug');




        function handleSlugInput() {
            slugInput.value = generateSlug(slugInput.value);
        }



        if (slugInput) {
            slugInput.addEventListener('input', function () {
                handleSlugInput();
            });
        }

        if (titleInput) {
            titleInput.addEventListener('input', function () {
                titleInput.value = generateInput(titleInput.value);
            });
        }



        if (metaTitleInput) {
            metaTitleInput.addEventListener('input', function () {
                metaTitleInput.value = generateInput(metaTitleInput.value);
            });
        }
        if (metaDesTextarea) {
            metaDesTextarea.addEventListener('input', function () {
                metaDesTextarea.value = generateInput(metaDesTextarea.value);
            });
        }


        if (metaKeywords) {
            metaKeywords.addEventListener('input', function () {
                metaKeywords.value = generateInput(metaKeywords.value);
            });
        }



        if (goBackButton) {
            goBackButton.addEventListener('click', function () {
                // Trigger the "Go Back" function
                window.history.back();
            });
        }



        // Clean up event listeners when the component unmounts
        return () => {
            if (goBackButton) {
                goBackButton.removeEventListener('click', function () {
                    window.history.back();
                });
            }


        };
    }, []);




    return (
        <>
            <Sidebar />
            <main class="content">
                <Navbar />

                <div className="py-4">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="#">
                                    <svg
                                        className="icon icon-xxs"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="#">Admin</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Edit Blog
                            </li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                        <div className="mb-3 mb-lg-0">
                            <h1 className="h4">      Edit Blog</h1>
                        </div>
                        <div>
                            {" "}
                            <Link
                                to={'/admin/allblogs'}
                                className="btn btn-primary d-inline-flex align-items-center"
                            >
                                <svg
                                    style={{ transform: "rotate(180deg)" }}
                                    className="icon icon-sm"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                Go back
                            </Link>
                        </div>
                    </div>
                </div>
                <form method="post" id="add-blog-form">
                    <div className="row">
                        <div className="col-md-8 mb-4">
                            <div className="card border-0 shadow components-section">
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="mb-4">
                                                <label htmlFor="title">Blog Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="title"
                                                    value={blog.title}
                                                    name="blogTitle"
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="exampleInputIconLeft">Blog Slug</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={20}
                                                            height={20}
                                                            viewBox="0 0 22 22"
                                                            id="link"
                                                        >
                                                            <g
                                                                fill="none"
                                                                fillRule="evenodd"
                                                                stroke="#000"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                transform="translate(1 1)"
                                                            >
                                                                <path d="M8 11a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07L9.75 3.18" />
                                                                <path d="M12 9a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="slug"
                                                        name="slug" value={blog.slug}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputIconLeft">Blog Content</label>
                                                <div id="editorjs" style={{ height: 400 }}>
                                                    <Link
                                                        to={`/admin/buildBlogs/${blog._id}`}
                                                        className="disabledbt"
                                                        id="EditDisable"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={16}
                                                            height={16}
                                                            style={{ transform: "scale(1.4)" }}
                                                            id="edit"
                                                        >
                                                            <path
                                                                fill="#fff"
                                                                stroke="#249fe6"
                                                                d="m6.131 11.99-1.337 1.338a.6.6 0 0 1-.85 0l-1.271-1.271a.6.6 0 0 1 0-.85L4.01 9.868"
                                                            />
                                                            <path
                                                                fill="#c2e4f8"
                                                                d="m6.12 11.979 6.374-6.344 1.063-3.188-3.188 1.063-6.375 6.375 2.125 2.094z"
                                                            />
                                                            <path
                                                                fill="none"
                                                                stroke="#249fe6"
                                                                d="m6.12 11.979 6.374-6.344 1.063-3.188-3.188 1.063-6.375 6.375 2.125 2.094zm6.263-6.038-2.287-2.288"
                                                            />
                                                        </svg>
                                                        Edit With Builder
                                                    </Link>
                                                </div>
                                                <br />
                                                <input type="hidden" name="blogContent" id="blogContent" />
                                            </div>
                                            <hr />
                                            <h5 className="mb-3"> Blog Metas</h5>
                                            <div className="mb-3">
                                                <label htmlFor="metaTitle">Meta Title</label>{" "}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="metaTitle"
                                                    name="metaTitle"
                                                    required="" value={blog.metaTitle}
                                                />
                                                <div className="invalid-feedback" id="metaTitleFeedback">
                                                    {" "}
                                                </div>
                                            </div>
                                            <div className="my-4">
                                                <label htmlFor="textarea">Meta Description</label>
                                                <textarea
                                                    className="form-control"
                                                    rows={4}
                                                    name="metaDes"
                                                    id="metaDes"
                                                    defaultValue={""} value={blog.metaDescription}
                                                />
                                                <div className="invalid-feedback" id="metaDesFeedback" />
                                            </div>
                                            <div className="my-4">
                                                <label htmlFor="textarea">Meta Keywords</label>
                                                <textarea
                                                    className="form-control"
                                                    rows={3}
                                                    name="metaKey"
                                                    id="metaKeywords"
                                                    defaultValue={""} value={blog.metaKeywords}
                                                />

                                                <div className="invalid-feedback" id="metaKeyFeedback" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card border-0 shadow components-section">
                                <div className="card-body">
                                    <div className="row">
                                        <h5> Publish</h5>
                                        <hr className=" d-none" />
                                        <div className="col-4 d-none">
                                            <div className="mb-4">
                                                <label className="my-1 me-2" htmlFor="Status">
                                                    Status
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-8  d-none">
                                            <div className="mb-4">
                                                <select
                                                    className="form-select"
                                                    id="Status"
                                                    name="status"
                                                    aria-label="Default select example"
                                                    fdprocessedid="b5ckv6"
                                                >
                                                    <option value={1} selected="">
                                                        Publish
                                                    </option>
                                                    <option value={2}>Draft</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-4  d-none">
                                            <div className="mb-4">
                                                <label className="my-1 me-2" htmlFor="visibility">
                                                    Visibility
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-8  d-none">
                                            <div className="mb-4">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="visibility"
                                                        id="flexSwitchCheckChecked"
                                                        defaultChecked="checked"
                                                        defaultValue={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className=" d-none" />
                                        <div className="col-12  d-none">
                                            <h5>categories</h5>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="categories"
                                                    id="categories1"
                                                    defaultValue="option1"
                                                    defaultChecked="checked"
                                                />
                                                <label className="form-check-label" htmlFor="categories1">
                                                    uncategorized
                                                </label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="col-12">
                                            <h5> Featured image </h5>
                                        </div>
                                        <input
                                            type="hidden"
                                            className="form-control imageopen"
                                            id="image"
                                            name="blogImg"
                                            defaultValue="/assets/img/image-placeholder.jpg"
                                        />
                                        <div className="input-group imageupload-bx mb-3">
                                            <img
                                                src={blog.image}
                                                className=" "
                                                id="image-preview"
                                                data-bs-toggle="modal"
                                                data-bs-target="#modal-default"
                                                onClick={getGallery} // Pass gallery._id as an argument here
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <div
                                                className="modal fade"
                                                id="modal-default"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="modal-default"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog modal-dialog-centered"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h2 className="h6 modal-title">Gallery</h2>
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                            />
                                                        </div>
                                                        <div className="modal-body">
                                                            <div id="gallery" className="row">

                                                                {isLoading ? (
                                                                    // Display loading skeletons while data is being fetched
                                                                    Array.from({ length: 8 }).map((_, index) => (
                                                                        <div className="col-md-6 col-lg-3" key={index}>

                                                                            <div className="blog-entry mb-4">
                                                                                <div className="skeleton mb-3" style={{ minHeight: 100, borderRadius: 5 }}>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))
                                                                ) :
                                                                    (gallery.map(gallery => (


                                                                        <div className="col-12 col-lg-6 col-xl-4 col-xxl-3 mb-3" key={gallery._id} >
                                                                            <img
                                                                                src={'http://localhost:8000/uploads/' + gallery.filePath}
                                                                                imageid={gallery._id}

                                                                                className="getimg"
                                                                                title={gallery.title}
                                                                                type={gallery.fileType}
                                                                                size={gallery.fileSize}
                                                                                dimensions={gallery.dimensions}
                                                                                date={gallery.createdAt}
                                                                                style={{ minHeight: 100, borderRadius: 5 }}
                                                                                data-bs-dismiss="modal"
                                                                            />

                                                                        </div>



                                                                    ))
                                                                    )}


                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary">
                                                                Choose
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-link text-gray-600 ms-auto"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-secondary" type="button" onClick={submitBlog}>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>


            </main>
        </>
    )
}

export default EditAdminBlogs