import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../../api/axiosInstance';
import { DataTable } from 'simple-datatables';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import toast from "react-hot-toast";
import LoadingTable from '../../components/LoadingTable';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const AllBlogs = () => {


    const navigate = useNavigate();


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/all-blogs');
            console.log(response.data); // Log the API response to the console
            setData(response.data.blogs); // Access the 'blogs' array in the response data
            setLoading(true); // Set loading to false when data is fetched

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(true); // Set loading to false even if there's an error
        }
    };


    useEffect(() => {

        fetchData();
    }, []);


    useEffect(() => {
        if (data.length > 0) {
            // Initialize the DataTable when data is available
            const modifiedData = data.map(item => [
                item._id,
                item.title,
                `<div class="d-flex" ><button class="dropdown-item d-flex align-items-center editbt" data-id="${item._id}" ><svg width="20px" class="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg> Edit </button>
                 <button data-id="${item._id}" class="dropdown-item d-flex align-items-center deletebt" href="#"><svg width="20px" class="dropdown-icon text-danger me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg> Delete</button></div>
                `
            ]).reverse(); // Reverse the order of data
            const table = new DataTable('#myTable', {
                data: {
                    headings: ['ID', 'Title', 'Action'],
                    data: modifiedData
                }
            });

            // Event delegation for handling edit button clicks
            const handleEditButtonClick = (event) => {
                navigate(`/admin/edit-blog/${event}`);

            };


            const handleDeleteButtonClick = async (event) => {

                try {
                    // Make an API request to delete the image
                    await axiosInstance.delete(`/admin/delete-blog/${event}`);
                    toast.success("Blog Deleted successfully!");
                    fetchData();
                } catch (error) {
                    console.error('Error deleting Blog:', error);
                    toast.error("Error deleting Blog!");

                }

            };


            function buttactive() {
                // Select all elements with the class 'editbt' and attach click event listeners to them
                const editButtons = document.querySelectorAll('.editbt');
                editButtons.forEach(button => {
                    button.addEventListener('click', (event) => {
                        const itemId = event.target.getAttribute('data-id');
                        handleEditButtonClick(itemId);
                    });
                });

                const deletebt = document.querySelectorAll('.deletebt');
                deletebt.forEach(button => {
                    button.addEventListener('click', (event) => {
                        const itemId = event.target.getAttribute('data-id');
                        handleDeleteButtonClick(itemId);
                    });
                });

            }
            buttactive();

            return () => {
                // Cleanup when the component unmounts
                table.destroy();
            };
        }
    }, [data, navigate]);


    return (
        <>

            <Sidebar />
            <main class="content">
                <Navbar />




                <div className="py-4">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="#">
                                    <svg
                                        className="icon icon-xxs"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="#">Admin</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                All Blogs
                            </li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                        <div className="mb-3 mb-lg-0">
                            <h1 className="h4"> All Blogs</h1>
                        </div>
                        <div>
                            <button
                                type="button"
                                id="goBackButton"
                                className="btn btn-primary d-inline-flex align-items-center"
                            >
                                <svg
                                    style={{ transform: "rotate(180deg)" }}
                                    className="icon icon-sm"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                Go back
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="table-responsive py-4" >

                        <table id="myTable" class="table table-flush" >

                            {setLoading ? (
                                <>
                                    <LoadingTable />

                                </>) : (
                                <></>
                            )}

                        </table>
                    </div>
                </div>

            </main >
        </>
    )
};

export default AllBlogs;

