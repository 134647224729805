import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../../api/axiosInstance';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import toast from 'react-hot-toast';
import Gallery from '../../components/Gallery';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Select from 'react-select';
import { triggerChooseimg } from '../../components/ChooseImg';
import { Link } from 'react-router-dom';


const AddPrivatestore = () => {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state

  const [loading, setLoading] = useState(true); // Add loading state
  const [data, setData] = useState([]);


  const [formData, setFormData] = useState({
    companyLogo: '',
    companyName: '',
    metaTitle: '',
    metaDescription: '',
    products: [],
    passwordStatus: false,
    password: '',
    status: true,
  });




  const handleselectChange = selectedValues => {
    const categoryIds = selectedValues.map(category => category.value); // Extracting ObjectId values

    setSelectedOptions(selectedValues);
    setFormData(prevData => ({
      ...prevData,
      products: categoryIds, // Setting the array of ObjectId values
    }));
  };



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
    console.log(formData); // Check if the state is updated
  };


  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/all-privateproduct');
      console.log('proo', response.data);
      setData(response.data.products);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();

  }, []);
  const submitData = async () => {
    setSubmitLoading(false);

    const image = document.getElementById('image-preview');


    const updatedFormData = {
      ...formData,
      companyLogo: image.src
    };

    try {
      const admintoken = localStorage.getItem('adminToken');
      if (admintoken) {
        await axiosInstance.post('/admin/create-privatestore', updatedFormData);
        console.log('Private Store Add successfully!');
        toast.success('Private Store Add successfully!');
        navigate('/admin/all-private-store/');
      }
    } catch (error) {
      console.error('Error On Private Store:', error);
      console.log(formData);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }

  };

  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <a href="#">
                  <svg
                    className="icon icon-xxs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Private Store
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4">Add Private Store</h1>
            </div>
            <div>
              {" "}
              <Link
                to='/admin/all-private-store/'
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  style={{ transform: "rotate(180deg)" }}
                  className="icon icon-sm"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Go back
              </Link>
            </div>
          </div>
        </div>
        <form method="post" id="add-blog-form">
          <div className="row">
            <div className="col-md-8 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-lg-12 col-sm-12">

                      <div className="row">

                        <div className='col-md-12'>
                          <div className="mb-4">
                            <label htmlFor="name">Company Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="companyName" value={formData.companyName}
                              name="companyName" onChange={handleChange}
                            />
                          </div>
                        </div>


                        <div className='col-md-12'>
                          <div className="mb-4">
                            <label htmlFor="name">Choose Products</label>

                            <Select
                              isMulti
                              value={selectedOptions} name="Category"
                              onChange={handleselectChange}
                              placeholder={loading ? 'Loading...' : 'Select options'}
                              options={data.map(category => ({
                                value: category._id,
                                label: category.title
                              }))}

                            />
                          </div>
                        </div>



                        {formData.passwordStatus === true && (<>
                          <div className='col-md-12'>
                            <div className="mb-4">
                              <label htmlFor="name">Store Password</label>
                              <input
                                type="password"
                                className="form-control"
                                id="password" value={formData.password}
                                name="password" onChange={handleChange}
                              />
                            </div>
                          </div>

                        </>)}



                      </div>

                      <hr />


                      <div className="row">
                        <div className='col-md-12'>
                          <div className="mb-4">
                            <label htmlFor="metaTitle">Meta Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="metaTitle" value={formData.metaTitle} name="metaTitle" onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className='col-md-12'>

                          <div className="mb-4">
                            <label htmlFor="metaDescription">Meta Description</label>
                            <textarea className="form-control"
                              value={formData.metaDescription}
                              rows="4" name="metaDescription" id="metaDescription" onChange={handleChange} ></textarea>
                          </div>

                        </div>


                      </div>




                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">
                  <div className="row">

                    <h5> Company Logo</h5>

                    <hr />

                    {formData.companyLogo}
                    <div className="input-group imageupload-bx mb-3">

                      <img
                        src="/assets/images/placeholder.png"
                        className=" "
                        id="image-preview"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-default"
                      />
                      <input
                        type="hidden"
                        className="form-control imageopen"
                        id="companyLogo"
                        name="companyLogo"
                        value={formData.companyLogo}
                        defaultValue="/assets/images/placeholder.png"
                        onChange={handleChange}
                      />
                    </div>



                    <div class="row">

                      <div className="col-6 ">
                        <div className="mb-4">
                          <label className="my-1 me-2" htmlFor="passwordStatus">
                            Password Status
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-4">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="passwordStatus"
                              id="passwordStatus"
                              defaultValue={1}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                    </div>


                    <div class="row">

                      <div className="col-4 ">
                        <div className="mb-4">
                          <label className="my-1 me-2" htmlFor="status">
                            Status
                          </label>
                        </div>
                      </div>
                      {formData.status}
                      <div className="col-8 ">
                        <div className="mb-4">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="status"
                              id="status"
                              defaultChecked="checked"
                              value={1} onChange={handleChange}

                            />
                          </div>
                        </div>
                      </div>

                    </div>


                    <div className="col-lg-4">

                      <Gallery />
                    </div>


                  </div>
                  <div className="col-12">

                    {SubmitLoading ? (

                      <button className="btn btn-secondary" type="button" onClick={submitData} >
                        Add Private Store
                      </button>
                    ) : (

                      <button class="btn btn-secondary btn-sm" type="button" disabled>
                        <span class="ms-1">Loading...</span>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>

                    )}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </form >


      </main >
    </>
  )
}

export default AddPrivatestore
