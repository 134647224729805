import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../../api/axiosInstance';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import toast from 'react-hot-toast';
import Gallery from '../../components/Gallery';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Select from 'react-select';
import { triggerChooseimg } from '../../components/ChooseImg';


const EditPrivatestore = () => {

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [nonSelectedOptions, setnonSelectedOptions] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state
  const [showPassword, setShowPassword] = useState(false);

  const [editorValue, setEditorValue] = useState('');

  const [ValueVariation, setValueVariation] = useState([]);
  const [Variation, setVariation] = useState([]);
  const [AllVariation, setAllVariation] = useState([]);
  const [AddVarLoading, setAddVarLoading] = useState(true); // Add loading state

  const { slug } = useParams();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    companyLogo: '',
    companyName: '',
    metaTitle: '',
    metaDescription: '',
    products: [],
    passwordStatus: false,
    password: '',
    status: true,
  });



  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/all-category');
      console.log(response.data.products);
      setData(response.data.products);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const [imageDivs, setImageDivs] = useState([]);


  const addImageDiv = () => {
    let newImageDiv;
    console.log('selectedOptions', selectedOptions)

    // Check if the selected type is 'Color + radio Button'
    if (formData.type === '4' || selectedOptions == '4') {
      newImageDiv = (
        <div key={imageDivs.length} className='col-md-4 mb-4 d-flex gap-2'>
          <input
            type="text"
            className="form-control"
            placeholder="Type Value"
            id={`imageproduct${imageDivs.length}`}
          />
          <input
            type="color" // Change input type to 'color'
            className="form-control color"
            placeholder="Type Value"
            name="color"
            id={`attribute${imageDivs.length}`}
          />
          <button className="btn btn-danger d-block m-auto" onClick={removeImageDiv}>
            Delete
          </button>
          <br />
        </div>
      );
    } else {
      newImageDiv = (
        // Your existing code for adding the default image div
        <div key={imageDivs.length} className='col-md-4 mb-4 d-flex gap-2'>
          <input
            type="text"
            className="form-control"
            placeholder="Type Value"
            id={`imageproduct${imageDivs.length}`}
          />
          <button className="btn btn-danger d-block m-auto" onClick={removeImageDiv}>
            Delete
          </button>
          <br />
        </div>
      );
    }


    const updatedImageDivs = [...imageDivs, newImageDiv];
    setImageDivs(updatedImageDivs);
    setTimeout(function () {
      triggerChooseimg();
    }, 1);
  };

  const removeImageDiv = (event) => {
    const button = event.target;
    const parentDiv = button.parentNode; // Accessing the parent div
    parentDiv.remove(); // Remove the parent div
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
  };



  const getCategory = async () => {
    try {
      const { data } = await axiosInstance.get(`/admin/get-privatestore/${slug}`);
      setData(data.Privatestore);
      console.log(data)
      setLoading(false); // Set loading to false when data is fetched
      // Set the formData.username based on the blog's slug
      setFormData((prevData) => ({
        ...prevData,
        companyLogo: data.Privatestore.companyLogo,
        companyName: data.Privatestore.companyName,
        metaTitle: data.Privatestore.metaTitle,
        metaDescription: data.Privatestore.metaDescription,
        products: data.Privatestore.products,
        passwordStatus: data.Privatestore.passwordStatus,
        password: data.Privatestore.password,
        status: data.Privatestore.status,
      }));


      const fetchData = async () => {
        try {
          const response = await axiosInstance.get('/all-privateproduct');
          console.log(response.data.products);
          setData(response.data.products);
          setLoading(false);

          const SelectData = response.data.products;

          if (Array.isArray(SelectData) && data.Privatestore.products && Array.isArray(data.Privatestore.products)) {
            const categoryIds = data.Privatestore.products;

            const categorizedOptions = SelectData.reduce((acc, category) => {
              const option = {
                value: category._id,
                label: category.title,
              };

              if (categoryIds.includes(category._id)) {
                acc.selectedOptions.push(option);
              } else {
                acc.nonSelectedOptions.push(option);
              }

              return acc;
            }, { selectedOptions: [], nonSelectedOptions: [] });

            setSelectedOptions(categorizedOptions.selectedOptions);
            setnonSelectedOptions(categorizedOptions.nonSelectedOptions);
          }


        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
        finally {
          setLoading(false);

        }
      };

      fetchData()
      // setTimeout(setnow, 2000);
    }
    catch (error) {
      console.log(error);
      toast.error("Error fetching Single company!");
      setLoading(false); // Set loading to false when data is fetched

    }
  };


  const handleselectChange = selectedValues => {
    const categoryIds = selectedValues.map(category => category.value); // Extracting ObjectId values

    setSelectedOptions(selectedValues);
    setFormData(prevData => ({
      ...prevData,
      products: categoryIds, // Setting the array of ObjectId values
    }));
  };



  const addVariations = async () => {
    setAddVarLoading(false)
    try {
      const response = await axiosInstance.get('/admin/all-attribute');
      console.log(response.data.Attribute);
      setVariation(response.data.Attribute);
      console.log('attribute', response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setAddVarLoading(true)
    }

  };

  const getAllVariations = async () => {
    try {
      const response = await axiosInstance.get('/admin/all-attribute');
      console.log('AllVariation', response.data.Attribute);
      setAllVariation(response.data.Attribute);

    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };

  const createButtons = () => {
    console.log('myvarition', Variation)
    return Variation.map((variation, index) => (
      <button
        key={index}
        className="btn btn-primary btn-sm mx-2"
        type="button"
        onClick={() => handleVariationClick(variation)}
      >
        {variation.name}
      </button>
    ));
  };

  const [selectedVariations, setSelectedVariations] = useState([]);


  const handleVariationClick = (variation) => {
    console.log('Clicked variation:', variation);
    const newValue = variation.value;
    const newName = variation.name;
    setSelectedVariations((oldVariations) => [...oldVariations, { value: newValue, name: newName }]);
  };




  useEffect(() => {

    getCategory();
    fetchData();

  }, []);


  const submitData = async () => {
    setSubmitLoading(false);

    const image = document.getElementById('image-preview');


    const updatedFormData = {
      ...formData,
      companyLogo: image.src
    };

    console.log(updatedFormData)
    try {
      const admintoken = localStorage.getItem('adminToken');
      if (admintoken) {
        await axiosInstance.put(`/admin/update-privatestore/${slug}`, updatedFormData);
        toast.success('Private Store Updated successfully!');
        navigate('/admin/all-private-store');
      }
    } catch (error) {
      console.error('Error On Private Store:', error);
      console.log(formData);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }

  };


  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <a href="#">
                  <svg
                    className="icon icon-xxs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Attribute
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4">Edit Attribute</h1>
            </div>
            <div>
              {" "}
              <Link
                to="/admin/all-attribute"
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center" onClick={() => navigate('/admin/all-company')}
              >
                <svg
                  style={{ transform: "rotate(180deg)" }}
                  className="icon icon-sm"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Go back
              </Link>
            </div>
          </div>
        </div>
        <div id="add-blog-form">
          <div className="row">
            <div className="col-md-8 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">


                  {loading ? (
                    <>
                      <div className="row mb-4">
                        <div className="col-lg-12 col-sm-12">
                          <div className="row">
                            <br />
                            <div className="col-md-12">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 70 }} > </p>
                              </div>
                            </div>


                            <div className="col-md-6">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 70 }} > </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 70 }} > </p>
                              </div>
                            </div>


                            <div className="col-md-6">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 70 }} > </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 70 }} > </p>
                              </div>
                            </div>


                            <div className="col-md-6">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 70 }} > </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 70 }} > </p>
                              </div>
                            </div>


                            <div className="col-md-12">
                              <div className="mb-4">
                                <p className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 135 }} > </p>
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>


                    </>) : (
                    <>



                      <div className="row mb-4">
                        <div className="col-lg-12 col-sm-12">


                          <div className="row">

                            <div className='col-md-12'>
                              <div className="mb-4">
                                <label htmlFor="name">Company Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="companyName" value={formData.companyName}
                                  name="companyName" onChange={handleChange}
                                />
                              </div>
                            </div>


                            <div className='col-md-12'>
                              <div className="mb-4">
                                <label htmlFor="name">Choose Products</label>

                                <Select
                                  isMulti
                                  value={selectedOptions} name="Category"
                                  onChange={handleselectChange}
                                  placeholder={loading ? 'Loading...' : 'Select options'}
                                  options={[...selectedOptions, ...nonSelectedOptions]}

                                />

                              </div>
                            </div>


                            {formData.passwordStatus === 'true' && (<>
                              <div className='col-md-12'>
                                <div className="mb-4">
                                  <label htmlFor="name">Store Password</label>
                                  <div className="input-group mb-3">

                                    <input
                                      type={showPassword ? 'text' : 'password'}
                                      className="form-control"
                                      id="password" value={formData.password}
                                      name="password" onChange={handleChange}
                                    />
                                    <button
                                      type="button"
                                      className="input-group-text bg-primary text-white"
                                      onClick={togglePasswordVisibility}
                                      id="basic-addon2"
                                    >
                                      {showPassword ? (
                                        <i className="ri-eye-fill" ></i>
                                      ) : (
                                        <i className="ri-eye-off-fill" ></i>
                                      )}
                                    </button>


                                  </div>

                                </div>
                              </div>

                            </>)}



                          </div>

                          <hr />


                          <div className="row">
                            <div className='col-md-12'>
                              <div className="mb-4">
                                <label htmlFor="metaTitle">Meta Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="metaTitle" value={formData.metaTitle} name="metaTitle" onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className='col-md-12'>

                              <div className="mb-4">
                                <label htmlFor="metaDescription">Meta Description</label>
                                <textarea className="form-control"
                                  value={formData.metaDescription}
                                  rows="4" name="metaDescription" id="metaDescription" onChange={handleChange} ></textarea>
                              </div>

                            </div>

                            <hr />


                            <div className="col-md-12 mb-3 ">
                              {AddVarLoading ? (
                                <button onClick={addVariations} className="btn btn-secondary btn-sm" type="button">

                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#1f2937" stroke-width="2" stroke-linecap="square" stroke-linejoin="arcs"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>

                                  Add Variations</button>

                              ) : (

                                <button class="btn btn-secondary btn-sm" type="button" disabled>
                                  <span class="ms-1">Loading...</span>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>

                              )}


                              <div id="VariationsContainer" >
                                {createButtons()}
                              </div>

                              <table class="table table-bordered mt-3">
                                <thead>
                                  <tr>
                                    <th scope="col" className="text-center">Name</th>
                                    <th scope="col" className="text-center" >Variation</th>
                                    <th scope="col" className="text-center">Regular ₹</th>
                                    <th scope="col" className="text-center">Sale ₹</th>
                                    <th scope="col" className="text-center">Stock</th>
                                    <th scope="col" className="text-center" style={{ maxWidth: '80px' }}>
                                      Action</th>
                                  </tr>
                                </thead>

                              </table>


                              <div id="VariationsValue" className="mt-4">

                                {Object.keys(formData?.variations || {}).map((variationType, index) => (
                                  <div key={index}>
                                    {formData.variations[variationType].map((variation, innerIndex) => (
                                      <div key={`${index}-${innerIndex}`} className="col-md-12 mb-4 d-flex gap-2">

                                        <input
                                          type="text"
                                          className="btn btn-secondary btn-sm m-0 mydisabled"
                                          placeholder="Regular Price"
                                          id={`imageproduct${innerIndex}`}
                                          name={variationType}
                                          disabled
                                          value={variationType} // Assuming the first key is the type (Color, Size, Weight)
                                        />

                                        <select className="form-control select" id="parent" name={variationType} >

                                          <option disabled selected> Select Variation
                                          </option>

                                          {AllVariation.map((Allvariation, innerIndex) => (

                                            <React.Fragment key={index}>
                                              {Allvariation.name === variationType && (
                                                <>
                                                  {Allvariation.value.map((value, innerIndex) => (
                                                    <option key={innerIndex} selected={variation[Object.keys(variation)[0]] == value} > {value}
                                                    </option>
                                                  ))
                                                  }
                                                </>
                                              )}
                                            </React.Fragment>

                                          ))}

                                        </select>


                                        {/* Assuming regular, sale, and stock fields are in a specific order */}
                                        {Object.values(variation).slice(1).map((value, inputIndex) => (
                                          <input
                                            key={inputIndex}
                                            type="number"
                                            className="form-control"
                                            placeholder={inputIndex === 0 ? 'Regular Price' : inputIndex === 1 ? 'Sale Price' : 'Stock'}
                                            id={`imageproduct${innerIndex}`}
                                            name={variationType}
                                            value={value}
                                            onChange={(event) => {
                                              const updatedVariation = [...formData.variations[variationType]];
                                              updatedVariation[innerIndex][Object.keys(variation)[inputIndex + 1]] = event.target.value;

                                              const updatedFormData = { ...formData };
                                              updatedFormData.variations[variationType] = updatedVariation;

                                              setFormData(updatedFormData);
                                            }}
                                          />
                                        ))}
                                        <button className="btn btn-danger d-block m-0" onClick={removeImageDiv}>
                                          Delete
                                        </button>
                                        <br />
                                      </div>
                                    ))}
                                  </div>
                                ))}



                                {/* Iterate through selectedVariations to display selected values */}
                                {selectedVariations.map((Variations, index) => (

                                  <div key={index} className='col-md-12 mb-4 d-flex gap-2'>


                                    <input key={index}
                                      type="text"
                                      className="btn btn-secondary btn-sm m-0 mydisabled"
                                      placeholder="Regular Price"
                                      id={`imageproduct${index}`}
                                      value={Variations.name}
                                      name={Variations.name}
                                      disabled
                                    />

                                    <select className="form-control select" id="parent" name={Variations.name} >

                                      <option disabled selected> Select Variation
                                      </option>
                                      {Variations.value.map((value, innerIndex) => (

                                        <option key={innerIndex}  > {value}
                                        </option>
                                      ))}
                                    </select>


                                    <input key={index}
                                      type="number"
                                      className="form-control"
                                      placeholder="Regular Price"
                                      id={`imageproduct${index}`}
                                      name={Variations.name}

                                    />

                                    <input key={index}
                                      type="text"
                                      className="form-control"
                                      placeholder="Sale Price"
                                      id={`imageproduct${index}`}
                                      name={Variations.name}

                                    />

                                    <input key={index}
                                      type="text"
                                      className="form-control"
                                      placeholder="Stock"
                                      id={`imageproduct${index}`}
                                      name={Variations.name}

                                    />


                                    <button className="btn btn-danger d-block m-0 " onClick={removeImageDiv}>
                                      Delete
                                    </button>
                                    <br />
                                  </div >

                                ))}

                              </div>


                            </div>


                          </div>







                        </div>
                      </div>


                    </>
                  )}

                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">

                  {loading ? (
                    <>

                      <div className="row">
                        <h5 className="skeleton" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 50 }}  > </h5>

                        <hr />

                        <p className="skeleton mb-3" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 250 }} > </p>

                        <div className="col-6 ">
                          <p className="skeleton mb-3" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 40 }} > </p>

                        </div>


                      </div>
                      <div className="col-6">
                        <p className="skeleton mb-3" style={{ width: '100%', borderRadius: 5, margin: '0px auto 10px', height: 40 }} > </p>

                      </div>




                    </>) : (
                    <>


                      <div className="row">



                        <h5> Company Logo</h5>

                        <hr />


                        <div className="input-group imageupload-bx mb-3">

                          <img
                            src={formData.companyLogo}
                            className=" "
                            id="image-preview"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-default"
                          />
                          <input
                            type="hidden"
                            className="form-control imageopen"
                            id="companyLogo"
                            name="companyLogo"
                            value={formData.companyLogo}
                            defaultValue="/assets/images/placeholder.png"
                            onChange={handleChange}
                          />
                        </div>



                        <div class="row">

                          <div className="col-6 ">
                            <div className="mb-4">
                              <label className="my-1 me-2" htmlFor="passwordStatus">
                                Password Status
                              </label>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-4">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="passwordStatus"
                                  id="passwordStatus"
                                  defaultValue={1}
                                  onChange={handleChange}
                                  defaultChecked={formData.passwordStatus === 'false' ? (``) : (`checked`)}

                                />
                              </div>
                            </div>
                          </div>

                        </div>


                        <div class="row">

                          <div className="col-4 ">
                            <div className="mb-4">
                              <label className="my-1 me-2" htmlFor="status">
                                Status
                              </label>
                            </div>
                          </div>

                          <div className="col-8 ">
                            <div className="mb-4">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="status"
                                  id="status"
                                  defaultChecked={formData.status === 'false' ? (``) : (`checked`)}
                                  value={1} onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                        </div>




                        <div className="col-lg-4">

                          <Gallery />
                        </div>


                      </div>

                    </>
                  )}

                  <div className="col-12">

                    {SubmitLoading ? (

                      <button className="btn btn-secondary" type="button" onClick={submitData} >
                        Update Private Store
                      </button>

                    ) : (

                      <button class="btn btn-secondary btn-sm" type="button" disabled>
                        <span class="ms-1">Loading...</span>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>

                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </main>
    </>
  )
}

export default EditPrivatestore